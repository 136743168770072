<template>
  <div class="overlay" v-show="isVisible">
    <div class="modal">
      <div class="header">
        <h2>Rollback Confirmation</h2>
        <span class="close" @click="cancel">×</span>
      </div>
      <div class="content">
        <p>Are you sure you want to rollback to the <strong>{{ title }}</strong> version?</p>
        <br>
        <p>We have backed up the Redis data before rolling back to the older version.</p>
      </div>
      <div class="actions">
        <button class="cancel" @click="cancel">Cancel</button>
        <button class="confirm" @click="confirm">Confirm</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "service"
    }
  },

  methods: {
    confirm() {
      // Emit confirm event to the parent component
      this.$emit("confirm", this.title);
    },

    cancel() {
      // Emit cancel event to the parent component
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
}

.modal {
  background: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  width: 80%; /* Adjust the width for responsiveness */
  max-width: 400px; /* Set a max-width for larger screens */
}

.header {
  background: #3498db;
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header h2 {
  margin: 0;
  font-size: 1.5em;
}

.close {
  cursor: pointer;
  font-size: 18px;
}

.content {
  padding: 20px;
  font-size: 14px;
}

.actions {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.confirm,
.cancel {
  cursor: pointer;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.confirm {
  background-color: #2ecc71;
  color: #fff;
}

.cancel {
  background-color: #e74c3c;
  color: #fff;
}
</style>
