<template>
  <div class="backup-container text-color">
    <!-- Backup List Container -->
    <div class="container backup-list-container">
      <div class="card">
        <div class="backup-list">
          <div class="card-header">
            <h2>Backup List</h2>
          </div>
          <br>
          <div v-for="(backup, index) in paginatedBackupList" :key="index" class="backup-item">
            <div class="backup-link-container">
              <button @click="rollbackNowWithConfirmation" class="btn-warning rollback-btn">Rollback</button>
              <ModalConfirm :isVisible="showConfirmModal" :title="backup.name" @confirm="rollbackNow" @cancel="closeModal" />
              <span class="backup-name">{{ backup.name }}</span>
              <span class="download-link">{{backup.size}}</span>
              <a @click="downloadBackup(backup.name)" :href="backup.url" class="download-link" >
                <span>download</span>
              </a>
            </div>
            <hr>
          </div>
          <div class="pagination">
            <button @click="previousPage" :disabled="currentPage === 1">Previous</button>
            <span>{{ currentPage }}</span>
            <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
          </div>
          <br>
        </div>
      </div>
    </div>

    <!-- Backup Times Input Container -->
    <div class="container backup-times">
      <div class="card">
        <div class="card-header">
          <h2>Backup Times</h2>
          <button @click="backupNow" class="btn-primary backup-now">Backup Now</button>
        </div>
        <div class="card-body">
          <div class="input-group">
            <div class="input-fields">
              <input v-model="hour" id="hour" type="number" min="0" max="23" placeholder="HH" />
              <span>:</span>
              <input v-model="minute" id="minute" type="number" min="0" max="59" placeholder="MM" />
              <button @click="addBackupTime" class="btn-primary">Add</button>
            </div>
          </div>
          <ul class="time-list">
            <li v-for="(time, index) in backupTimes" :key="index" class="time-item">
              <span>Local: {{ time.localHour }}:{{ time.localMinute }} - UTC: {{ time.utcHour }}:{{ time.utcMinute }}</span>
              <button @click="deleteBackupTime(index)" class="btn-delete">Delete</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import moment from 'moment';
import axios from 'axios';
import store from "@/store";
import VaIconBackup from "@/components/icons/VaIconBackup";
import ModalConfirm from "@/pages/admin/backup/ModalConfirm";



export default {
  components: {ModalConfirm},
  data() {
    return {
      showConfirmModal: false,
      newTime: '', // Combined hour and minute in the format "XX:XX"
      countryCode: '',
      hour: '',
      minute: '',
      backupTimes: [],
      loading: false,
      timeLimit: 3, // Number of times user can set per day
      localTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Get local time zone from browser

      //backup list
      backupList: [], // Initialize an empty array to store the user backup list
      itemsPerPage: 5, // Number of items to display per page
      currentPage: 1 // Current page number
    };
  },
  mounted() {
    this.fetchBackupListFromAPI();
    this.fetchBackupTimesFromAPI();
  },

  computed: {
    // Calculate the total number of pages based on the itemsPerPage and backupList length
    totalPages() {
      return Math.ceil(this.backupList.length / this.itemsPerPage);
    },
    // Calculate the paginated backup list based on the currentPage and itemsPerPage
    paginatedBackupList() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.backupList.slice(startIndex, endIndex);
    }
  },
  methods: {
    rollbackNowWithConfirmation() {
      // Show the confirmation modal
      this.showConfirmModal = true;
    },
    closeModal() {
      // Close the modal
      this.showConfirmModal = false;
    },
    async fetchBackupTimesFromAPI() {
      try {
        const headers = {
          'Content-Type': 'application/json',
          'token': store.getters.token
        };
        const response = await axios.get('https://api.landofbytes.com/service/redis/backup/times', { headers });

        // Assuming response.data is an array of backup times in the format "HH:mm"
        this.backupTimes = response.data.map(time => {
          const [hour, minute] = time.split(':'); // Split the time into hour and minute
          const utcTime = moment.utc(`${hour}:${minute}`, 'HH:mm'); // Parse UTC time
          const localTime = moment(utcTime).local(); // Convert UTC time to local time

          return {
            localHour: localTime.format('HH'),
            localMinute: localTime.format('mm'),
            utcHour: hour,
            utcMinute: minute
          };
        });
      } catch (error) {
        console.error('Failed to fetch backup times from API:', error);
      }
    },
    async DeleteBackupTimesApi(hour,min) {
      try {
        const headers = {
          'Content-Type': 'application/json',
          'token': store.getters.token
        };
        const url = "https://api.landofbytes.com/service/redis/backup/times/"
        const deletedDate = hour + ":" + min;
        const response = await axios.delete(url + deletedDate, { headers });

        // Assuming response.data is an array of backup times in the format "HH:mm"
        console.log(response.data);
      } catch (error) {
        console.error('Failed to fetch backup times from API:', error);
      }
    },
    async AddBackupTimesApi(hour,min) {
      try {
        const headers = {
          'Content-Type': 'application/json',
          'token': store.getters.token
        };
        const response = await axios.post('https://api.landofbytes.com/service/redis/backup/times', {'hour':hour,'min':min},{ headers });

        // Assuming response.data is an array of backup times in the format "HH:mm"
        console.log(response.data);
      } catch (error) {
        console.error('Failed to fetch backup times from API:', error);
      }
    },
    async fetchBackupListFromAPI() {
      try {
        const headers = {
          'Content-Type':'application/json',
          'token': store.getters.token
        }
        const response = await axios.get('https://api.landofbytes.com/service/redis/backup',{headers});
        this.backupList = response.data; // Assuming your API returns an array of backups
      } catch (error) {
        this.backupList = [{name: "bbbbb", url:"33333"},{name: "bbbbb", url:"33333"},{name: "bbbbb", url:"33333"},{name: "bbbbb", url:"33333"},{name: "bbbbb", url:"33333"},{name: "bbbbb", url:"33333"},{name: "bbbbb", url:"33333"},{name: "bbbbb", url:"33333"},{name: "bbbbb", url:"33333"},{name: "bbbbb", url:"33333"},{name: "bbbbb", url:"33333"},{name: "bbbbb", url:"33333"},{name: "bbbbb", url:"33333"},{name: "bbbbb", url:"33333"},{name: "bbbbb", url:"33333"},{name: "bbbbb", url:"33333"},{name: "bbbbb", url:"33333"},{name: "bbbbb", url:"33333"},{name: "bbbbb", url:"33333"}]
        console.error('Failed to fetch backup list from API:', error);
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    async downloadBackup(fileName) {
      try {
        const headers = {
          'Content-Type': 'application/json',
          'TOKEN': store.getters.token
        };

        const response = await axios({
          url: 'https://download.landofbytes.com/download/' + fileName,
          method: 'GET',
          responseType: 'blob', // Set the response type to blob
          headers: headers,
        });

        // Create a blob from the response data
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;

        // Append the link to the document and trigger the click event
        document.body.appendChild(link);
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
      } catch (error) {
        console.error('Failed to download file:', error);
      }
    },
    async rollbackNow(fileName) {
      try {
        const headers = {
          'Content-Type': 'application/json',
          'TOKEN': store.getters.token
        };

        const response = await axios({
          url: 'https://api.landofbytes.com/service/redis/backup/rollback/' + fileName,
          method: 'GET',
          headers: headers,
        });


      } catch (error) {
        console.error('Failed to download file:', error);
      }
    },
    async backupNow() {
      try {
        const headers = {
          'Content-Type': 'application/json',
          'TOKEN': store.getters.token
        };

        const response = await axios({
          url: 'https://api.landofbytes.com/service/redis/backup/now',
          method: 'GET',
          headers: headers,
        });
        console.log(response.data);
      } catch (error) {
        console.error('Failed to download file:', error);
      }
    },
    addBackupTime() {
      const hour = parseInt(this.hour, 10);
      const minute = parseInt(this.minute, 10);

      // Validate hour and minute inputs
      if (isNaN(hour) || isNaN(minute) || hour < 0 || hour > 23 || minute < 0 || minute > 59) {
        alert("Invalid hour or minute. Please enter valid values.");
        return;
      }

      const localTime = moment().set({
        hour: hour,
        minute: minute
      });

      const utcTime = moment(localTime).utc();

      // this.backupTimes.push({
      //   localHour: localTime.format('HH'),
      //   localMinute: localTime.format('mm'),
      //   utcHour: utcTime.format('HH'),
      //   utcMinute: utcTime.format('mm')
      // });

      this.AddBackupTimesApi(parseInt(utcTime.format('HH'), 10),parseInt(utcTime.format('mm'), 10));

      this.hour = ''; // Clear the hour input field after adding time
      this.minute = ''; // Clear the minute input field after adding time
      this.fetchBackupTimesFromAPI()
    },
    deleteBackupTime(index) {
      this.DeleteBackupTimesApi(this.backupTimes[index].utcHour,this.backupTimes[index].utcMinute);
      this.backupTimes.splice(index, 1);
    },
    async updateBackupTimes() {
      this.loading = true;
      try {
        const utcTimes = this.backupTimes.map(time => {
          return {
            localTime: `${time.localHour}:${time.localMinute}`,
            utcTime: `${time.utcHour}:${time.utcMinute}`
          };
        });

        // Simulate API call
        await new Promise(resolve => setTimeout(resolve, 1000));

        // Handle success, show notifications, etc.
      } catch (error) {
        // Handle error, show error message to the user
        console.error(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.backup-times {
  display: flex;
  justify-content: center;
  align-items: center;
  /*height: 100vh;*/
}
.backup-now{
  margin-top: 12px;
  width: 80%;
}

.rollback-btn{
  width: 20%;
  font-size: 70%;
  margin-left: 2%;
}

.card {
  width: 450px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.06);
}

.card-header {
  /*background-color: #3498db; !* Updated header background color *!*/
  /*color: b;*/
  padding: 15px;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.card-header h2 {
  /*background-color: #3498db; !* Updated header background color *!*/
  /*color: b;*/
  font-size: 22px;
}

.card-body {
  padding: 20px;
}

.input-group {
  margin-bottom: 20px;
  align-content: center;
}

.input-group label {
  margin-right: 50px;
  font-size: 16px;
}
.input-fields {
  display: flex;
  align-items: center;
}

.input-fields input {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 12px; /* Increase padding for more height */
  font-size: 18px; /* Increase font size */
  width: calc(30% - 10px); /* Adjust the width */
  margin-right: 10px;
  margin-left: 10px/* Add margin between hour and minute inputs */
}
.input-fields span{
  font-size: 36px; /* Set the font size of the colon separator */
}
input {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  width: calc(50% - 10px);
}

.time-list {
  list-style: none;
  padding: 0;
}

.time-item {
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.btn-primary {
  background-color: var(--va-primary);;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
}

.btn-primary:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.btn-primary:hover {
  background-color: #2980b9;
}
.btn-delete {
  background-color: var(--va-danger);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.btn-warning {
  background-color: var(--va-warning);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.btn-warning:hover {
  background-color: var(--va-danger);
}
.btn-delete:hover {
  background-color: var(--va-danger);
}

/*backup list */
@media (max-width: 1212px) {
  .backup-container {
    flex-direction: column;
    align-items: center
  }
  .backup-list-container {
    order: 2; /* This will make the backup list appear below other cron stuff on smaller screens */
  }
}

.container {
  flex: 1;
  font-family: 'Roboto', Arial, sans-serif;
  max-width: 500px;
  padding: 20px;
  /*background-color: #ffffff;*/
  display: flex;
  justify-content: center;
  align-items: center;

}

@media (max-width: 480px) {
  .container {
    padding: 10px;
  }
}

@media (min-width: 481px) and (max-width: 1212px) {
  .container {
    max-width: 300px;
  }
}
.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  justify-content: space-around
}

.pagination button {
  margin-right: 10px;
  padding: 8px 16px;
  font-size: 14px;
  background-color: var(--va-primary);;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
}

.pagination span {
  font-size: 14px;
  font-weight: bold;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination i {
  margin-right: 5px;
}


@media (max-width: 480px) {
  .pagination button {
    padding: 4px 8px;
    font-size: 12px;
  }
}
.backup-container {
  display: flex;
  flex-direction: row-reverse;
  align-items: baseline;
  justify-content: space-around;
}

.backup-list {
  flex: 1;
}
@media (max-width: 1212px) {
  .backup-container {
    flex-direction: column;
    align-items: center
  }

  .container {
    max-width: 100%;
    margin-top: 20px;
  }

}
.backup-link-container {
  display: flex;
  align-items: center;
}






/*lksdjfkljsdkljfkljsdlkjflsdklfjsdkljfksdflkjdskfjkdlsjfklsdflksdjfljsdlkfjsdkljfdksljfklsd?*/
.backup-list-container {
  /*background-color: #f7f7f7; !* Set the background color for the backup list container *!*/
  /*border: 1px solid #ccc;*/
  /*border-radius: 8px;*/
  padding: 20px;
  margin-top: 20px; /* Add margin to separate it from the backup times section */
}

.backup-list {
  /* You can customize the styles of backup list items here */
  /* ... */
}

.backup-item {
  margin-bottom: 15px; /* Add margin between backup items */
}



.backup-name {
  flex: 1;
  margin-right: 10px;
  margin-left: 10px;
  font-weight: bold; /* Make the backup name bold */
}

.download-link {
  color: var(--va-info);
  margin-right: 10px;
  text-decoration: none;
}
.download-link:hover {
  color: var(--va-info);
  margin-right: 10px;
  text-decoration: none;
  cursor: grab;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  justify-content: space-around;
}



.pagination span {
  font-size: 14px;
  font-weight: bold;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination i {
  margin-right: 5px;
}

@media (max-width: 480px) {
  .pagination button {
    padding: 4px 8px;
    font-size: 12px;
  }
}
.text-color {
  color: var(--va-dark);
}
</style>
